import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import {IPatientDetails, LanguageCodes} from "../../models";
import {useNavigate, useParams} from "react-router-dom";
import {fetchPatient, saveLanguagePreference} from "../../store/actions/screenerQuestions.action";
import {DateTime} from "luxon";
import {PatientScreenerStatusPill} from "../generic/PatientScreenerStatusPill";
import {ArrowLeftIcon} from "@heroicons/react/20/solid";
import {setLanguagePreference, setScreenName} from "../../store/actions/account.action";
import {MixpanelContext} from "../../analytics/Mixpanel";


const Languages = [
    {
        code: LanguageCodes.EN_US,
        name: 'English'
    },
    {
        code: LanguageCodes.ES,
        name: 'Spanish'
    }
]

const PatientProfileScreen = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageCodes | undefined>(undefined);
    const {nickName, hasAcceptedTerms} = useSelector((state: StateParams) => state.account)

    const onLanguageSelection = useCallback((selectedLanguage: LanguageCodes) => {
        if ([LanguageCodes.EN_US, LanguageCodes.ES].includes(selectedLanguage)) {
            setSelectedLanguage(selectedLanguage);
            dispatch(setLanguagePreference({languagePreference: selectedLanguage}))
        } else {
            setSelectedLanguage(LanguageCodes.EN_US);
            dispatch(setLanguagePreference({languagePreference: LanguageCodes.EN_US}))
        }
    }, [dispatch]);

    const submitPreferredLanguage = useCallback(() => {
        if (selectedLanguage) {
            dispatch(saveLanguagePreference({preferredLanguage: selectedLanguage}));
            if (hasAcceptedTerms) {
                dispatch(setScreenName({screenName: 'login'}));
                navigate('/screenerQuestion');
            } else if (!hasAcceptedTerms) {
                dispatch(setScreenName({screenName: 'loginT&C'}));
                navigate('/termsAndCondition');
            }
        } else {
            alert('Please select a language');
        }
    }, [hasAcceptedTerms, selectedLanguage]);

    const renderLangPreferenceWidget = useCallback(() => {
        return <div className='w-full h-72 flex flex-col items-center justify-center'>
            <p className='text-lg font-bold'>{'Preferred Language (Idioma Preferido)'}</p>
            <div className='flex mt-8 flex-wrap content-center justify-center'>
                {
                    Languages.map((lang, index) => {
                        return <div key={index} className='flex items-center p-4 bg-white rounded-md mr-4 mt-2 cursor-pointer hover:bg-amber-50' onClick={(e) => {
                            onLanguageSelection(lang.code);
                        }}>
                            <input id={`${lang.code}LP`} type="radio" required name='languagePreference' checked={lang.code === selectedLanguage}
                                   onChange={(e)=> {
                                       onLanguageSelection(lang.code);
                                   }} className='h-3 w-3 mr-2 text-orange focus:ring-0'/>
                            <label htmlFor={`${lang.code}LP`} className='text-sm'>{lang.name}</label>
                        </div>
                    })
                }
            </div>
            <div className="w-full flex flex-col items-center justify-center mt-10">
                <button data-test="practice-continue"
                        className={`md:w-60 sm:w-50 w-60 h-10 font-bold rounded-lg text-white ${!selectedLanguage ? 'bg-borderGrey' : 'bg-lightOrange' }`}
                        onClick={submitPreferredLanguage}>
                    Continue
                </button>
            </div>
        </div>
    }, [submitPreferredLanguage]);

    return (
        <main className="h-screen mx-auto px-1 bg-sky-light-lg bg-no-repeat bg-cover">
            <div className="flex space-x-10 pt-2">
                <h1 className="text-xl font-semibold"> Welcome, {nickName}</h1>
            </div>
            {renderLangPreferenceWidget()}
        </main>
    )
}

export default PatientProfileScreen;
